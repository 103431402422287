<template>
  <div class="w-100">
    <b-form-input
      class="w-100"
      v-model="value"
      :required="true"
      :show-labels="false"
      @input="val => (value = value.toUpperCase())"
    >
    </b-form-input>
  </div>
</template>

<script>
export default {
  name: "UpperCaseInput",
  data() {
    return {
      value: null
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return false;
    }
  },
  created() {
    // this.value = this.getInitialValue();
  },
  mounted() {
    this.value = this.params.value;
  }
};
</script>
